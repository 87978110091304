/** @jsx jsx */
import { jsx, Container, Grid, Heading, Box } from "theme-ui";

// import { Link } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";

import Link from "../components/link";

const HeroSection = () => (
  <div
    sx={{
      backgroundColor: "secondary",
      color: "white",
      p: 3,
      "text-align": "center",
    }}
  >
    <div sx={{ margin: "0 auto", maxWidth: (t) => t.sizes.container }}>
      <Heading p={1}>
        <span
          sx={{
            py: 1,
            px: 3,
            bg: "logo-text",
            border: (theme) => `2px solid ${theme.colors["logo-text"]}`,
            borderRadius: "5px",
            lineHeight: 2,
          }}
        >
          Experiments with thinking tools
        </span>
      </Heading>
    </div>
  </div>
);

const InfoCard = ({ title, link, children }) => {
  return (
    <Link to={link} sx={{ textDecoration: "none" }}>
      <Box m={2} p={2} sx={{ border: "default" }}>
        <div>
          <b>{title}</b>
        </div>
        <Box sx={{ height: 130, mt: 3 }}>{children}</Box>
      </Box>
    </Link>
  );
};

const MobileApp = () => (
  <InfoCard
    title="Mobile app"
    link="https://play.google.com/store/apps/details?id=com.ngkeep&hl=en&gl=US"
  >
    <p>
      Mobile app to organize notes using mind map like hierarchy with the focus
      on detailed notes.
    </p>
    <small>Not actively developed</small>
  </InfoCard>
);

const DesktopApp = () => (
  <InfoCard title="Desktop App" link="/desktop-app">
    Notegraph original idea, no released versions so far.
  </InfoCard>
);

const Projects = () => (
  <Container mt={3}>
    <Heading as="h3" sx={{ textAlign: "center", m: 2 }}>
      Projects
    </Heading>
    <Grid gap={2} columns={[1, 3]} sx={{ alignItems: "center" }}>
      <MobileApp />
      <DesktopApp />
    </Grid>
  </Container>
);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    <Projects />
  </Layout>
);

export default IndexPage;
